$('.card-carousel .owl-carousel').owlCarousel({
  loop: true,
  margin: 10,
  items: 1,
  nav: true,
  dots: true,
  navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
})

let membersSlider = $('#members-slider .owl-carousel').owlCarousel({
  loop: true,
  margin: 10,
  items: 1,
  nav: false,
  dots: false,
  onInitialized: counter,
  onChanged: counter,
})

$('#members-slider .slider__next').click(function() {
  membersSlider.trigger('next.owl.carousel');
})
$('#members-slider .slider__prev').click(function() {
  membersSlider.trigger('prev.owl.carousel');
})

let mediaSlider = $('#media-slider .owl-carousel').owlCarousel({
  loop: true,
  margin: 10,
  items: 1,
  nav: false,
  dots: false,
  onInitialized: counter,
  onChanged: counter,
})

$('#media-slider .slider__next').click(function() {
  mediaSlider.trigger('next.owl.carousel');
})
$('#media-slider .slider__prev').click(function() {
  mediaSlider.trigger('prev.owl.carousel');
})

function counter(event) {
  if (!event.namespace) {
    return;
  }
  var slides = event.relatedTarget;

  $('.slider__counter').html(`
    <span class="slider__count-active">
      ${slides.relative(slides.current()) + 1}
    </span>
    <span class="slider__count-separator mr-1">/</span>
    <span class="slider__count-length">${slides.items().length}</span>
  `);
}

let heroSlider = $('#hero-slider .owl-carousel').owlCarousel({
  loop: true,
  margin: 10,
  items: 1,
  nav: false,
  dots: true,
  dotsContainer: '.hero__slider-pagination'
})

$('#hero-slider .hero__slider-next').click(function() {
  heroSlider.trigger('next.owl.carousel');
})
$('#hero-slider .hero__slider-prev').click(function() {
  heroSlider.trigger('prev.owl.carousel');
})
